import { Auth, JsonRpcRequest, ParticleRpcRequest, rpcUrl } from '@particle-network/auth';
import { v4 as uuidv4 } from 'uuid';
import { HttpConnection } from './connection';
import {
    IEthereumProvider,
    IJsonRpcConnection,
    IJsonRpcProvider,
    notSupportMethods,
    particleSignerMethods,
    ProviderAccounts,
    ProviderError,
    signerMethods,
} from './types';

export class ParticleDelegateProvider extends IJsonRpcProvider implements IEthereumProvider {
    public readonly isParticleDelegateProvider = true;

    private connection: IJsonRpcConnection;

    private chainId: number | undefined;

    constructor(private auth: Auth, readonly signerProvider: IEthereumProvider) {
        super();
        this.connection = this.setConnection();
        this.listenEvent();
    }

    private listenEvent() {
        const event = this.events;
        this.signerProvider.on('connect', (result) => event.emit('connect', result));
        this.signerProvider.on('disconnect', (result) => event.emit('disconnect', result));
        this.signerProvider.on('message', (result) => event.emit('message', result));
        this.signerProvider.on('chainChanged', (result) => event.emit('chainChanged', result));
        this.signerProvider.on('accountsChanged', (result) => event.emit('accountsChanged', result));
    }

    private setConnection(): IJsonRpcConnection {
        return new HttpConnection({
            url: `${rpcUrl()}/evm-chain`,
            basicCredentials: this.auth.basicCredentials(),
            chainId: () => this.chainId ?? this.auth.chainId(),
            authentication: this.auth.config,
        });
    }

    async disconnect(): Promise<void> {
        const provider = this.signerProvider as any;
        if (provider.disconnect && typeof provider.disconnect === 'function') {
            try {
                await provider.disconnect();
            } catch (e) {
                // ignore
            }
        }
    }

    /**
     * Enable the provider by invoking the `eth_requestAccounts` RPC method.
     */
    public async enable(): Promise<ProviderAccounts> {
        const provider = this.signerProvider as any;
        let result;
        if (provider.enable && typeof provider.enable === 'function') {
            try {
                result = await provider.enable();
            } catch (e) {
                // ignore
                result = await this.request({
                    method: 'eth_requestAccounts',
                });
            }
        }
        return result;
    }

    public async request(request: Partial<JsonRpcRequest>): Promise<any> {
        if (!request.method || notSupportMethods.includes(request.method)) {
            return Promise.reject(ProviderError.unsupportedMethod());
        }
        if (!this.connection.connected) {
            await this.open();
        }

        const rpcRequest = {
            id: request.id ?? uuidv4(),
            jsonrpc: request.jsonrpc ?? '2.0',
            method: request.method,
            params: request.params,
        };

        if (signerMethods.includes(rpcRequest.method) || this.isParticleSignerMethod(rpcRequest.method)) {
            console.log('Particle Signer Provider Request', rpcRequest);
            return await this.signerProvider.request(rpcRequest);
        } else {
            try {
                this.chainId = Number(await this.signerProvider.request({ method: 'eth_chainId' }));
            } catch (error) {
                this.chainId = Number(this.auth.chainId());
            }
            const particlePpcRequest = { ...rpcRequest, chainId: this.chainId };
            console.log('Particle Provider Delegate Request', particlePpcRequest);
            return await this.requestStrict(particlePpcRequest);
        }
    }

    private isParticleSignerMethod(method: string): boolean {
        return (this.signerProvider as any).isParticleNetwork && particleSignerMethods.includes(method);
    }

    private async requestStrict(request: ParticleRpcRequest): Promise<any> {
        return this.connection.send(request).then((output) => {
            if (output.error) {
                return Promise.reject(output.error);
            } else {
                return Promise.resolve(output.result);
            }
        });
    }

    private async open() {
        await this.connection.open();
    }
}
