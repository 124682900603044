import { Auth, intToHex, JsonRpcRequest, ParticleRpcRequest, rpcUrl } from '@particle-network/auth';
import { Chain } from '@particle-network/common';
import { v4 as uuidv4 } from 'uuid';
import { AuthEVMAdapter } from './auth-adapter';
import { HttpConnection } from './connection';
import {
    IEthereumProvider,
    IJsonRpcConnection,
    IJsonRpcProvider,
    notSupportMethods,
    particleSignerMethods,
    ProviderAccounts,
    ProviderError,
    signerMethods,
} from './types';
import { getVersion } from './utils';

export class ParticleProvider extends IJsonRpcProvider implements IEthereumProvider {
    public readonly isParticleNetwork = true;

    private connection: IJsonRpcConnection;

    private authAdapter: AuthEVMAdapter;

    constructor(private auth: Auth) {
        super();
        this.auth = auth;
        this.connection = this.setConnection();
        this.authAdapter = new AuthEVMAdapter(this.auth);
        this.auth.on('chainChanged', (chain: Chain) => {
            if (chain.name !== 'Solana') {
                this.emit('chainChanged', intToHex(chain.id));
            }
        });
        if (typeof window !== 'undefined' && window.particle) {
            window.particle.particleProvider = this;
        }
    }

    get version() {
        console.log('get version');
        return getVersion();
    }

    private setConnection(): IJsonRpcConnection {
        return new HttpConnection({
            url: `${rpcUrl()}/evm-chain`,
            basicCredentials: this.auth.basicCredentials(),
            chainId: () => this.auth.chainId(),
            authentication: this.auth.config,
        });
    }

    private emit(eventName: string | symbol, ...args: any[]): boolean {
        return this.events.emit(eventName, ...args);
    }

    public async disconnect(): Promise<void> {
        return this.auth.logout();
    }

    /**
     * Enable the provider by invoking the `eth_requestAccounts` RPC method.
     */
    public async enable(): Promise<ProviderAccounts> {
        return this.request({
            method: 'eth_requestAccounts',
        });
    }

    public async request(request: Partial<JsonRpcRequest>): Promise<any> {
        if (!request.method || notSupportMethods.includes(request.method)) {
            return Promise.reject(ProviderError.unsupportedMethod());
        }
        if (!this.connection.connected) {
            await this.open();
        }

        console.log('Particle Provider Request', request);

        const rpcRequest = {
            chainId: Number(this.auth.chainId()),
            id: request.id ?? uuidv4(),
            jsonrpc: request.jsonrpc ?? '2.0',
            method: request.method,
            params: request.params,
        };

        if (signerMethods.includes(request.method) || particleSignerMethods.includes(request.method)) {
            return this.authAdapter.request(request);
        } else {
            return this.requestStrict(rpcRequest);
        }
    }

    private async requestStrict(request: ParticleRpcRequest): Promise<any> {
        return this.connection.send(request).then((output) => {
            if (output.error) {
                return Promise.reject(output.error);
            } else {
                return Promise.resolve(output.result);
            }
        });
    }

    protected async open() {
        await this.connection.open();
        this.connection.on('close', () => this.emit('disconnect'));
        this.emit('connect', {
            chainId: intToHex(this.auth.chainId()),
        });
    }

    protected async close() {
        await this.connection.close();
    }
}
