const host = 'https://static.particle.network';

const getChainIcon = (chainName: string) => {
    // https://docs.particle.network/developers/node-service/evm-chains-api#structure
    return `${host}/token-list/${chainName.toLowerCase()}/native.png`;
};

export interface ChainInfo {
    id: ChainId;
    name: ChainName;
    network: string;
    icon: string;
    rpcUrl: string;
    nativeCurrency: {
        name: string;
        symbol: string;
        decimals: number;
    };
    chainType: string;
    supportEIP1559: boolean;
    blockExplorerUrls: string[];
    fullname: string;
    faucetUrl?: string;
}

const chainMap = {
    Solana: {
        icon: getChainIcon('solana'),
        nativeCurrency: { name: 'SOL', symbol: 'SOL', decimals: 9 },
        chainType: 'solana',
        supportEIP1559: false,
        faucetUrl: '',
    },
    Ethereum: {
        icon: getChainIcon('ethereum'),
        nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    BSC: {
        icon: getChainIcon('bsc'),
        nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    opBNB: {
        icon: getChainIcon('bsc'),
        nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    Polygon: {
        icon: getChainIcon('polygon'),
        nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    Avalanche: {
        icon: getChainIcon('avalanche'),
        nativeCurrency: { name: 'AVAX', symbol: 'AVAX', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    Moonbeam1287: {
        icon: getChainIcon('moonbeam'),
        nativeCurrency: {
            name: 'Dev',
            symbol: 'DEV',
            decimals: 18,
        },
        chainType: 'evm',
        supportEIP1559: true,
    },
    Moonbeam: {
        icon: getChainIcon('moonbeam'),
        nativeCurrency: { name: 'GLMR', symbol: 'GLMR', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    Moonriver: {
        icon: getChainIcon('moonriver'),
        nativeCurrency: { name: 'MOVR', symbol: 'MOVR', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    Heco: {
        icon: getChainIcon('heco'),
        nativeCurrency: { name: 'HT', symbol: 'HT', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    Fantom: {
        icon: getChainIcon('fantom'),
        nativeCurrency: { name: 'FTM', symbol: 'FTM', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },

    Arbitrum: {
        icon: getChainIcon('arbitrum'),
        nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },

    ArbitrumGoerli: {
        icon: getChainIcon('arbitrum'),
        nativeCurrency: {
            name: 'Arbitrum Görli Ether',
            symbol: 'AGOR',
            decimals: 18,
        },
        chainType: 'evm',
        supportEIP1559: true,
    },
    Harmony: {
        icon: getChainIcon('harmony'),
        nativeCurrency: { name: 'ONE', symbol: 'ONE', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    Aurora: {
        icon: getChainIcon('aurora'),
        nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    Optimism: {
        icon: getChainIcon('optimism'),
        nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18,
        },
        chainType: 'evm',
        supportEIP1559: false,
    },
    KCC: {
        icon: getChainIcon('kcc'),
        nativeCurrency: { name: 'KCS', symbol: 'KCS', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    PlatON: {
        icon: getChainIcon('platon'),
        nativeCurrency: { name: 'LAT', symbol: 'LAT', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    Tron: {
        icon: getChainIcon('tron'),
        nativeCurrency: { name: 'TRX', symbol: 'TRX', decimals: 6 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    Okc: {
        icon: getChainIcon('okc'),
        nativeCurrency: { name: 'OKT', symbol: 'OKT', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    OKBC: {
        icon: getChainIcon('okc'),
        nativeCurrency: { name: 'OKB', symbol: 'OKB', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    ThunderCore: {
        icon: getChainIcon('thundercore'),
        nativeCurrency: { name: 'ThunderCore Token', symbol: 'TT', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    Cronos: {
        icon: getChainIcon('cronos'),
        nativeCurrency: { name: 'Cronos', symbol: 'CRO', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    OasisEmerald: {
        icon: getChainIcon('oasisemerald'),
        nativeCurrency: { name: 'OasisEmerald', symbol: 'ROSE', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    Gnosis: {
        icon: getChainIcon('gnosis'),
        nativeCurrency: { name: 'Gnosis', symbol: 'XDAI', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    Celo: {
        icon: getChainIcon('celo'),
        nativeCurrency: { name: 'Celo', symbol: 'CELO', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    Klaytn: {
        icon: getChainIcon('klaytn'),
        nativeCurrency: { name: 'Klaytn', symbol: 'KLAY', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    Scroll: {
        icon: getChainIcon('scroll'),
        nativeCurrency: { name: 'Scroll', symbol: 'ETH', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    zkSync: {
        icon: getChainIcon('zksync'),
        nativeCurrency: { name: 'zkSync', symbol: 'ETH', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    Metis: {
        icon: getChainIcon('metis'),
        nativeCurrency: { name: 'Metis', symbol: 'METIS', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    ConfluxESpace: {
        icon: getChainIcon('confluxespace'),
        nativeCurrency: { name: 'CFX', symbol: 'CFX', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    MAPO: {
        icon: getChainIcon('mapprotocol'),
        nativeCurrency: { name: 'MAPO', symbol: 'MAPO', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    PolygonZkEVM: {
        icon: getChainIcon('polygonzkevm'),
        nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    BaseGoerli: {
        icon: getChainIcon('base'),
        nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    Linea: {
        icon: getChainIcon('linea'),
        nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
    Combo: {
        icon: getChainIcon('combo'),
        nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    Mantle: {
        icon: getChainIcon('mantle'),
        nativeCurrency: { name: 'BIT', symbol: 'BIT', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    ZkMeta: {
        icon: getChainIcon('zkmeta'),
        nativeCurrency: { name: 'IDE', symbol: 'IDE', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: false,
    },
    Taiko: {
        icon: getChainIcon('taiko'),
        nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
        chainType: 'evm',
        supportEIP1559: true,
    },
};

export const Tron = {
    id: 728126428,
    name: 'Tron',
    network: 'Mainnet',
    fullname: 'Tron Mainnet',
    rpcUrl: 'https://api.trongrid.io',
    blockExplorerUrls: ['https://tronscan.io'],
    ...chainMap.Tron,
};

export const TronShasta = {
    id: 2494104990,
    name: 'Tron',
    network: 'Shasta',
    fullname: 'Tron Shasta',
    rpcUrl: 'https://api.shasta.trongrid.io',
    blockExplorerUrls: ['https://shasta.tronscan.org'],
    ...chainMap.Tron,
};

export const TronNile = {
    id: 3448148188,
    name: 'Tron',
    network: 'Nile',
    fullname: 'Tron Nile',
    rpcUrl: 'https://nile.trongrid.io',
    blockExplorerUrls: ['https://nile.tronscan.org'],
    faucetUrl: 'https://nileex.io/join/getJoinPage',
    ...chainMap.Tron,
};

export const PlatON = {
    id: 210425,
    name: 'PlatON',
    network: 'Mainnet',
    fullname: 'PlatON Mainnet',
    rpcUrl: 'https://openapi2.platon.network/rpc',
    blockExplorerUrls: ['https://scan.platon.network'],
    ...chainMap.PlatON,
};

export const PlatONTestnet = {
    id: 2206132,
    name: 'PlatON',
    network: 'Testnet',
    fullname: 'PlatON Testnet',
    rpcUrl: 'https://devnetopenapi2.platon.network/rpc',
    blockExplorerUrls: ['https://devnet2scan.platon.network'],
    faucetUrl: 'https://devnet2faucet.platon.network/faucet/',
    ...chainMap.PlatON,
};

export const KCC = {
    id: 321,
    name: 'KCC',
    network: 'Mainnet',
    fullname: 'KCC Mainnet',
    rpcUrl: 'https://rpc-mainnet.kcc.network',
    blockExplorerUrls: ['https://explorer.kcc.io/en'],
    ...chainMap.KCC,
};

export const KCCTestnet = {
    id: 322,
    name: 'KCC',
    network: 'Testnet',
    fullname: 'KCC Testnet',
    rpcUrl: 'https://rpc-testnet.kcc.network',
    blockExplorerUrls: ['https://scan-testnet.kcc.network'],
    faucetUrl: 'https://faucet-testnet.kcc.network/',
    ...chainMap.KCC,
};

export const Optimism = {
    id: 10,
    name: 'Optimism',
    network: 'Mainnet',
    fullname: 'Optimism Mainnet',
    rpcUrl: 'https://mainnet.optimism.io/',
    blockExplorerUrls: ['https://optimistic.etherscan.io'],
    ...chainMap.Optimism,
};

export const OptimismGoerli = {
    id: 420,
    name: 'Optimism',
    network: 'Testnet',
    fullname: 'Optimism Goerli',
    rpcUrl: 'https://goerli.optimism.io/',
    blockExplorerUrls: ['https://goerli-optimism.etherscan.io'],
    faucetUrl: 'https://faucet.triangleplatform.com/optimism/goerli',
    ...chainMap.Optimism,
};

export const Aurora = {
    id: 1313161554,
    name: 'Aurora',
    network: 'Mainnet',
    fullname: 'Aurora Mainnet',
    rpcUrl: 'https://mainnet.aurora.dev',
    blockExplorerUrls: ['https://explorer.aurora.dev'],
    ...chainMap.Aurora,
};

export const AuroraTestnet = {
    id: 1313161555,
    name: 'Aurora',
    network: 'Testnet',
    fullname: 'Aurora Testnet',
    rpcUrl: 'https://testnet.aurora.dev',
    blockExplorerUrls: ['https://explorer.testnet.aurora.dev'],
    faucetUrl: 'https://aurora.dev/faucet',
    ...chainMap.Aurora,
};

export const Harmony = {
    id: 1666600000,
    name: 'Harmony',
    network: 'Mainnet',
    fullname: 'Harmony Mainnet',
    rpcUrl: 'https://api.harmony.one',
    blockExplorerUrls: ['https://explorer.harmony.one'],
    ...chainMap.Harmony,
};

export const HarmonyTestnet = {
    id: 1666700000,
    name: 'Harmony',
    network: 'Testnet',
    fullname: 'Harmony Testnet',
    rpcUrl: 'https://api.s0.b.hmny.io',
    blockExplorerUrls: ['https://explorer.pops.one'],
    faucetUrl: 'https://faucet.pops.one/',
    ...chainMap.Harmony,
};

export const ArbitrumOne = {
    id: 42161,
    name: 'Arbitrum',
    network: 'Mainnet',
    fullname: 'Arbitrum One Mainnet',
    rpcUrl: 'https://arb1.arbitrum.io/rpc',
    blockExplorerUrls: ['https://arbiscan.io', 'https://explorer.arbitrum.io'],
    ...chainMap.Arbitrum,
};

export const ArbitrumNova = {
    id: 42170,
    name: 'Arbitrum',
    network: 'Mainnet',
    fullname: 'Arbitrum Nova Mainnet',
    rpcUrl: 'https://nova.arbitrum.io/rpc',
    blockExplorerUrls: ['https://nova.arbiscan.io'],
    ...chainMap.Arbitrum,
};

export const ArbitrumGoerli = {
    id: 421613,
    name: 'Arbitrum',
    network: 'Goerli',
    fullname: 'Arbitrum Goerli',
    rpcUrl: 'https://goerli-rollup.arbitrum.io/rpc',
    blockExplorerUrls: ['https://goerli.arbiscan.io/', 'https://goerli-rollup-explorer.arbitrum.io'],
    faucetUrl: 'https://faucet.triangleplatform.com/arbitrum/goerli',
    ...chainMap.ArbitrumGoerli,
};

export const Fantom = {
    id: 250,
    name: 'Fantom',
    network: 'Mainnet',
    fullname: 'Fantom Mainnet',
    rpcUrl: 'https://rpc.ftm.tools',
    blockExplorerUrls: ['https://ftmscan.com'],
    ...chainMap.Fantom,
};

export const FantomTestnet = {
    id: 4002,
    name: 'Fantom',
    network: 'Testnet',
    fullname: 'Fantom Testnet',
    rpcUrl: 'https://rpc.testnet.fantom.network',
    blockExplorerUrls: ['https://testnet.ftmscan.com'],
    faucetUrl: 'https://faucet.fantom.network/',
    ...chainMap.Fantom,
};

export const Heco = {
    id: 128,
    name: 'Heco',
    network: 'Mainnet',
    fullname: 'Heco Mainnet',
    rpcUrl: 'https://http-mainnet.hecochain.com',
    blockExplorerUrls: ['https://hecoinfo.com'],
    ...chainMap.Heco,
};

// export const HecoTestnet = {
//     id: 256,
//     name: 'Heco',
//     network: 'Testnet',
//     fullname: 'Heco Testnet',
//     rpcUrl: 'https://http-testnet.hecochain.com',
//     blockExplorerUrls: ['https://testnet.hecoinfo.com'],
//     faucetUrl: 'https://scan-testnet.hecochain.com/faucet',
//     ...chainMap.Heco,
// };

export const Moonriver = {
    id: 1285,
    name: 'Moonriver',
    network: 'Mainnet',
    fullname: 'Moonriver Mainnet',
    rpcUrl: 'https://rpc.api.moonriver.moonbeam.network',
    blockExplorerUrls: ['https://moonriver.moonscan.io'],

    ...chainMap.Moonriver,
};

export const MoonriverTestnet = {
    id: 1287,
    name: 'Moonriver',
    network: 'Testnet',
    fullname: 'Moonriver Testnet',
    rpcUrl: 'https://rpc.api.moonbase.moonbeam.network',
    blockExplorerUrls: ['https://moonbase.moonscan.io'],
    faucetUrl: 'https://apps.moonbeam.network/moonbase-alpha/faucet/',
    ...chainMap.Moonbeam1287,
};

export const Moonbeam = {
    id: 1284,
    name: 'Moonbeam',
    network: 'Mainnet',
    fullname: 'Moonbeam Mainnet',
    rpcUrl: 'https://rpc.api.moonbeam.network',
    blockExplorerUrls: ['https://moonbeam.moonscan.io'],
    ...chainMap.Moonbeam,
};

export const MoonbeamTestnet = {
    id: 1287,
    name: 'Moonbeam',
    network: 'Testnet',
    fullname: 'Moonbeam Testnet',
    rpcUrl: 'https://rpc.api.moonbase.moonbeam.network',
    blockExplorerUrls: ['https://moonbase.moonscan.io'],
    faucetUrl: 'https://apps.moonbeam.network/moonbase-alpha/faucet/',
    ...chainMap.Moonbeam1287,
};

export const Avalanche = {
    id: 43114,
    name: 'Avalanche',
    network: 'Mainnet',
    fullname: 'Avalanche Mainnet',
    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
    blockExplorerUrls: ['https://snowtrace.io'],
    ...chainMap.Avalanche,
};

export const AvalancheTestnet = {
    id: 43113,
    name: 'Avalanche',
    network: 'Testnet',
    fullname: 'Avalanche Testnet',
    rpcUrl: 'https://api.avax-test.network/ext/bc/C/rpc',
    blockExplorerUrls: ['https://testnet.snowtrace.io'],
    faucetUrl: 'https://faucet.avax.network/',
    ...chainMap.Avalanche,
};

export const Polygon = {
    id: 137,
    name: 'Polygon',
    network: 'Mainnet',
    fullname: 'Polygon Mainnet',
    rpcUrl: 'https://polygon-rpc.com',
    blockExplorerUrls: ['https://polygonscan.com'],
    ...chainMap.Polygon,
};

export const PolygonMumbai = {
    id: 80001,
    name: 'Polygon',
    network: 'Mumbai',
    fullname: 'Polygon Mumbai',
    rpcUrl: 'https://matic-mumbai.chainstacklabs.com',
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
    faucetUrl: 'https://faucet.polygon.technology/',
    ...chainMap.Polygon,
};

export const BSC = {
    id: 56,
    name: 'BSC',
    network: 'Mainnet',
    fullname: 'BNB Chain Mainnet',
    rpcUrl: 'https://bsc-dataseed1.binance.org',
    ...chainMap.BSC,
    blockExplorerUrls: ['https://bscscan.com'],
};

export const BSCTestnet = {
    id: 97,
    name: 'BSC',
    network: 'Testnet',
    fullname: 'BNB Chain Testnet',
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    ...chainMap.BSC,
    blockExplorerUrls: ['https://testnet.bscscan.com'],
    faucetUrl: 'https://testnet.bnbchain.org/faucet-smart',
};

export const opBNBTestnet = {
    id: 5611,
    name: 'opBNB',
    network: 'Testnet',
    fullname: 'opBNB Testnet',
    rpcUrl: 'https://opbnb-testnet-rpc.bnbchain.org',
    ...chainMap.opBNB,
    blockExplorerUrls: ['https://opbnbscan.com'],
    faucetUrl: '',
};

export const Ethereum = {
    id: 1,
    name: 'Ethereum',
    network: 'Mainnet',
    fullname: 'Ethereum Mainnet',
    rpcUrl: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    blockExplorerUrls: ['https://etherscan.io'],
    ...chainMap.Ethereum,
};

export const EthereumSepolia = {
    id: 11155111,
    name: 'Ethereum',
    network: 'Sepolia',
    fullname: 'Ethereum Sepolia',
    rpcUrl: 'https://eth-sepolia.g.alchemy.com/v2/demo',
    ...chainMap.Ethereum,
    blockExplorerUrls: ['https://sepolia.etherscan.io'],
    faucetUrl: 'https://faucet.quicknode.com/drip',
};

export const EthereumGoerli = {
    id: 5,
    name: 'Ethereum',
    network: 'Goerli',
    fullname: 'Ethereum Goerli',
    rpcUrl: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    ...chainMap.Ethereum,
    blockExplorerUrls: ['https://goerli.etherscan.io'],
    faucetUrl: 'https://goerlifaucet.com/',
};

export const Solana = {
    id: 101,
    name: 'Solana',
    network: 'Mainnet',
    fullname: 'Solana Mainnet',
    rpcUrl: 'https://api.mainnet-beta.solana.com',
    blockExplorerUrls: [],
    ...chainMap.Solana,
};

export const SolanaTestnet = {
    id: 102,
    name: 'Solana',
    network: 'Testnet',
    fullname: 'Solana Testnet',
    rpcUrl: 'https://api.testnet.solana.com',
    blockExplorerUrls: [],
    ...chainMap.Solana,
    faucetUrl: 'https://solfaucet.com/',
};

export const SolanaDevnet = {
    id: 103,
    name: 'Solana',
    network: 'Devnet',
    fullname: 'Solana Devnet',
    rpcUrl: 'https://api.devnet.solana.com',
    ...chainMap.Solana,
    faucetUrl: 'https://solfaucet.com/',
    blockExplorerUrls: [],
};

export const OKC = {
    id: 66,
    name: 'OKC',
    network: 'Mainnet',
    fullname: 'OKTC Mainnet',
    rpcUrl: 'https://exchainrpc.okex.org',
    ...chainMap.Okc,
    blockExplorerUrls: ['https://www.oklink.com/okc'],
};

export const OKCTestnet = {
    id: 65,
    name: 'OKC',
    network: 'Testnet',
    fullname: 'OKTC Testnet',
    rpcUrl: 'https://exchaintestrpc.okex.org',
    ...chainMap.Okc,
    blockExplorerUrls: ['https://www.oklink.com/okc-test'],
    faucetUrl: 'https://docs.oxdex.com/v/en/help/gitter',
};

export const OKBCTestnet = {
    id: 195,
    name: 'OKBC',
    network: 'Testnet',
    fullname: 'OKBC Testnet',
    rpcUrl: 'https://okbtestrpc.okbchain.org',
    ...chainMap.OKBC,
    blockExplorerUrls: ['https://www.oklink.com/cn/okbc-test'],
    faucetUrl: 'https://www.okx.com/cn/okbc/faucet',
};

export const ThunderCore = {
    id: 108,
    name: 'ThunderCore',
    network: 'Mainnet',
    fullname: 'ThunderCore Mainnet',
    rpcUrl: 'https://mainnet-rpc.thundercore.com',
    ...chainMap.ThunderCore,
    blockExplorerUrls: ['https://viewblock.io/thundercore'],
};

export const ThunderCoreTestnet = {
    id: 18,
    name: 'ThunderCore',
    network: 'Testnet',
    fullname: 'ThunderCore Testnet',
    rpcUrl: 'https://testnet-rpc.thundercore.com',
    ...chainMap.ThunderCore,
    blockExplorerUrls: ['https://explorer-testnet.thundercore.com', 'https://faucet-testnet.thundercore.com'],
    faucetUrl: 'https://faucet-testnet.thundercore.com/',
};

export const Cronos = {
    id: 25,
    name: 'Cronos',
    network: 'Mainnet',
    fullname: 'Cronos Mainnet',
    rpcUrl: 'https://evm.cronos.org',
    ...chainMap.Cronos,
    blockExplorerUrls: ['https://cronoscan.com'],
};

export const CronosTestnet = {
    id: 338,
    name: 'Cronos',
    network: 'Testnet',
    fullname: 'Cronos Testnet',
    rpcUrl: 'https://evm-t3.cronos.org',
    ...chainMap.Cronos,
    blockExplorerUrls: ['https://testnet.cronoscan.com'],
    faucetUrl: 'https://cronos.org/faucet',
};

export const OasisEmerald = {
    id: 42262,
    name: 'OasisEmerald',
    network: 'Mainnet',
    fullname: 'OasisEmerald Mainnet',
    rpcUrl: 'https://emerald.oasis.dev',
    ...chainMap.OasisEmerald,
    blockExplorerUrls: ['https://explorer.emerald.oasis.dev'],
};

export const OasisEmeraldTestnet = {
    id: 42261,
    name: 'OasisEmerald',
    network: 'Testnet',
    fullname: 'OasisEmerald Testnet',
    rpcUrl: 'https://testnet.emerald.oasis.dev',
    ...chainMap.OasisEmerald,
    blockExplorerUrls: ['https://testnet.explorer.emerald.oasis.dev'],
    faucetUrl: 'https://faucet.testnet.oasis.dev/',
};

export const Gnosis = {
    id: 100,
    name: 'Gnosis',
    network: 'Mainnet',
    fullname: 'Gnosis Mainnet',
    rpcUrl: 'https://rpc.ankr.com/gnosis',
    ...chainMap.Gnosis,
    blockExplorerUrls: ['https://gnosisscan.io', 'https://blockscout.com/xdai/mainnet'],
};

export const GnosisTestnet = {
    id: 10200,
    name: 'Gnosis',
    network: 'Testnet',
    fullname: 'Gnosis Testnet',
    rpcUrl: 'https://optimism.gnosischain.com',
    ...chainMap.Gnosis,
    blockExplorerUrls: ['https://blockscout.com/gnosis/chiado'],
    faucetUrl: 'https://gnosisfaucet.com',
};

export const Celo = {
    id: 42220,
    name: 'Celo',
    network: 'Mainnet',
    fullname: 'Celo Mainnet',
    rpcUrl: 'https://rpc.ankr.com/celo',
    ...chainMap.Celo,
    blockExplorerUrls: ['https://explorer.celo.org/mainnet'],
};

export const CeloTestnet = {
    id: 44787,
    name: 'Celo',
    network: 'Testnet',
    fullname: 'Celo Testnet',
    rpcUrl: 'https://alfajores-forno.celo-testnet.org',
    ...chainMap.Celo,
    blockExplorerUrls: ['https://explorer.celo.org/alfajores'],
    faucetUrl: ' https://celo.org/developers/faucet',
};

export const Klaytn = {
    id: 8217,
    name: 'Klaytn',
    network: 'Mainnet',
    fullname: 'Klaytn Mainnet',
    rpcUrl: 'https://cypress.fandom.finance/archive',
    ...chainMap.Klaytn,
    blockExplorerUrls: ['https://scope.klaytn.com'],
};

export const KlaytnTestnet = {
    id: 1001,
    name: 'Klaytn',
    network: 'Testnet',
    fullname: 'Klaytn Testnet',
    rpcUrl: 'https://api.baobab.klaytn.net:8651',
    ...chainMap.Klaytn,
    blockExplorerUrls: ['https://baobab.scope.klaytn.com'],
    faucetUrl: 'https://baobab.wallet.klaytn.foundation/faucet',
};

export const Scroll = {
    id: 534353,
    name: 'Scroll',
    network: 'Testnet',
    fullname: 'Scroll Alpha Testnet',
    rpcUrl: 'https://alpha-rpc.scroll.io/l2',
    ...chainMap.Scroll,
    blockExplorerUrls: ['https://blockscout.scroll.io'],
};

export const zkSync = {
    id: 324,
    name: 'zkSync',
    network: 'Mainnet',
    fullname: 'zkSync Era Mainnet',
    rpcUrl: 'https://zksync2-mainnet.zksync.io',
    ...chainMap.zkSync,
    blockExplorerUrls: ['https://explorer.zksync.io'],
};

export const zkSyncTestnet = {
    id: 280,
    name: 'zkSync',
    network: 'Testnet',
    fullname: 'zkSync Era Testnet',
    rpcUrl: 'https://zksync2-testnet.zksync.dev',
    ...chainMap.zkSync,
    blockExplorerUrls: ['https://goerli.explorer.zksync.io'],
    faucetUrl: 'https://portal.zksync.io/faucet',
};

export const Metis = {
    id: 1088,
    name: 'Metis',
    network: 'Mainnet',
    fullname: 'Metis Mainnet',
    rpcUrl: 'https://andromeda.metis.io/?owner=1088',
    ...chainMap.Metis,
    blockExplorerUrls: ['https://andromeda-explorer.metis.io'],
};

export const MetisGoerli = {
    id: 599,
    name: 'Metis',
    network: 'Goerli',
    fullname: 'Metis Goerli',
    rpcUrl: 'https://goerli.gateway.metisdevops.link',
    ...chainMap.Metis,
    blockExplorerUrls: ['https://goerli.explorer.metisdevops.link/'],
    faucetUrl: 'https://goerli.faucet.metisdevops.link',
};

export const ConfluxESpace = {
    id: 1030,
    name: 'ConfluxESpace',
    network: 'Mainnet',
    fullname: 'Conflux eSpace Mainnet',
    rpcUrl: 'https://evm.confluxrpc.com',
    blockExplorerUrls: ['https://evm.confluxscan.net'],
    ...chainMap.ConfluxESpace,
};

export const ConfluxESpaceTestnet = {
    id: 71,
    name: 'ConfluxESpace',
    network: 'Testnet',
    fullname: 'Conflux eSpace Testnet',
    rpcUrl: 'https://evmtestnet.confluxrpc.com',
    faucetUrl: 'https://efaucet.confluxnetwork.org/',
    blockExplorerUrls: ['https://evmtestnet.confluxscan.net'],
    ...chainMap.ConfluxESpace,
};

export const MAPOMainnet = {
    id: 22776,
    name: 'MAPProtocol',
    network: 'Mainnet',
    fullname: 'MAP Protocol Mainnet',
    rpcUrl: 'https://rpc.maplabs.io',
    blockExplorerUrls: ['https://mapscan.io'],
    ...chainMap.MAPO,
};

export const MAPOTestnet = {
    id: 212,
    name: 'MAPProtocol',
    network: 'Testnet',
    fullname: 'MAP Protocol Testnet',
    rpcUrl: 'https://testnet-rpc.maplabs.io',
    blockExplorerUrls: ['https://testnet.mapscan.io'],
    faucetUrl: 'https://faucet.mapprotocol.io/',
    ...chainMap.MAPO,
};

export const PolygonZkEVM = {
    id: 1101,
    name: 'PolygonZkEVM',
    network: 'Mainnet',
    fullname: 'Polygon zkEVM Mainnet',
    rpcUrl: 'https://zkevm-rpc.com',
    blockExplorerUrls: ['https://zkevm.polygonscan.com'],
    ...chainMap.PolygonZkEVM,
};
export const PolygonZkEVMTestnet = {
    id: 1442,
    name: 'PolygonZkEVM',
    network: 'Testnet',
    fullname: 'Polygon zkEVM Testnet',
    rpcUrl: 'https://rpc.public.zkevm-test.net',
    blockExplorerUrls: ['https://testnet-zkevm.polygonscan.com'],
    faucetUrl: 'https://public.zkevm-test.net/',
    ...chainMap.PolygonZkEVM,
};

export const BaseGoerliTestnet = {
    id: 84531,
    name: 'Base',
    network: 'Goerli',
    fullname: 'Base Goerli',
    rpcUrl: 'https://base-goerli.public.blastapi.io',
    blockExplorerUrls: ['https://goerli.basescan.org'],
    faucetUrl: 'https://bridge.base.org/deposit',
    ...chainMap.BaseGoerli,
};

export const LineaGoerli = {
    id: 59140,
    name: 'Linea',
    network: 'Goerli',
    fullname: 'Linea Goerli',
    rpcUrl: 'https://rpc.goerli.linea.build',
    blockExplorerUrls: ['https://goerli.lineascan.build'],
    faucetUrl: 'https://faucet.goerli.linea.build',
    ...chainMap.Linea,
};
export const ComboTestnet = {
    id: 91715,
    name: 'Combo',
    network: 'Testnet',
    fullname: 'Combo Testnet',
    rpcUrl: 'https://test-rpc.combonetwork.io',
    blockExplorerUrls: ['https://combotrace-testnet.nodereal.io'],
    faucetUrl: '', // 通过 BNB Chain 的 Faucet，然后 Bridge 到 Combo
    ...chainMap.Combo,
};

export const MantleTestnet = {
    id: 5001,
    name: 'Mantle',
    network: 'Testnet',
    fullname: 'Mantle Testnet',
    rpcUrl: 'https://rpc.testnet.mantle.xyz',
    blockExplorerUrls: ['https://explorer.testnet.mantle.xyz'],
    faucetUrl: 'https://faucet.testnet.mantle.xyz/',
    ...chainMap.Mantle,
};

export const ZkMetaTestnet = {
    id: 12009,
    name: 'zkMeta',
    network: 'Testnet',
    fullname: 'zkMeta Testnet',
    rpcUrl: 'https://pre-alpha-zkrollup-rpc.opside.network/era7',
    blockExplorerUrls: ['https://era7.zkevm.opside.info'],
    faucetUrl: '',
    ...chainMap.ZkMeta,
};

export const TaikoTestnet = {
    id: 167005,
    name: 'Taiko',
    network: 'Testnet',
    fullname: 'Taiko Testnet',
    rpcUrl: 'https://rpc.test.taiko.xyz',
    blockExplorerUrls: ['https://explorer.test.taiko.xyz'],
    faucetUrl: 'https://bridge.test.taiko.xyz',
    ...chainMap.Taiko,
};

export const ParticleChains: {
    [key: string]: ChainInfo;
} = {
    OKC,
    OKCTestnet,
    OKBCTestnet,
    PlatON,
    PlatONTestnet,
    Harmony,
    HarmonyTestnet,
    Heco,
    // HecoTestnet,
    KCC,
    KCCTestnet,
    Optimism,
    OptimismGoerli,
    Aurora,
    AuroraTestnet,
    ArbitrumOne,
    ArbitrumNova,
    ArbitrumGoerli,
    Fantom,
    FantomTestnet,
    Moonbeam,
    MoonbeamTestnet,
    Moonriver,
    MoonriverTestnet,
    Avalanche,
    AvalancheTestnet,
    Polygon,
    PolygonMumbai,
    BSC,
    BSCTestnet,
    opBNBTestnet,
    Ethereum,
    EthereumGoerli,
    EthereumSepolia,
    Tron,
    TronShasta,
    TronNile,
    Solana,
    SolanaDevnet,
    SolanaTestnet,
    ThunderCore,
    ThunderCoreTestnet,
    Cronos,
    CronosTestnet,
    OasisEmerald,
    OasisEmeraldTestnet,
    Gnosis,
    GnosisTestnet,
    Celo,
    CeloTestnet,
    Klaytn,
    KlaytnTestnet,
    Scroll,
    zkSync,
    zkSyncTestnet,
    Metis,
    MetisGoerli,
    ConfluxESpace,
    ConfluxESpaceTestnet,
    MAPOMainnet,
    MAPOTestnet,
    PolygonZkEVM,
    PolygonZkEVMTestnet,
    BaseGoerliTestnet,
    LineaGoerli,
    ComboTestnet,
    MantleTestnet,
    ZkMetaTestnet,
    TaikoTestnet,
};

export type ChainType = 'solana' | 'evm';

export type ChainName = string;

export type ChainId = number;
export interface Chain {
    id: ChainId;
    name: ChainName;
}

export const getChainIcons = () => {
    return Object.fromEntries(
        Object.keys(ParticleChains).map((key) => {
            return [key, ParticleChains[key].icon];
        })
    );
};
